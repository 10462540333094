import React, { ReactElement, useState, useEffect } from "react";
import {
  Input,
  Button,
  Typography,
  Flex,
  Card,
  Select,
  Tag,
  Grid,
  Col,
  Row,
  message,
} from "antd";
import {
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  APIExpert,
  apiExpertToExpert,
  Expert,
  expertOpts,
  get_random_text,
  getExperts,
  submitQuestionWithHist,
} from "../code/utils";
import { ChatMessageProps, ChatMessage } from "../components/ChatMessage";
import { ChatBox } from "../components/ChatBox";
import { useRef } from "react";
import { Transition } from "react-transition-group";
import { useUser } from "../components/AppLayout";
import VideoStream from "../components/VideoStream";

const duration = 1000;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounting: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const defaultStyleChat = {
  transition: `margin-bottom ${duration}ms ease-in-out`,
  marginBottom: "35vh",
  width: "100%",
};

const transitionStylesChat = {
  entering: { marginBottom: "35vh" },
  entered: { marginBottom: "35vh" },
  exiting: { marginBottom: 0 },
  exited: { marginBottom: 0 },
  unmounting: { marginBottom: 0 },
  unmounted: { marginBottom: 0 },
};

const { Title } = Typography;

const Chat: React.FC = () => {
  const params = useParams();
  const [focused, setFocused] = useState<boolean>(false);
  const [messageHistory, setMessageHistory] = useState<Array<ChatMessageProps>>(
    []
  );
  const [errors, setErrors] = useState<Array<string>>([]);
  const [curText, setCurText] = useState<string>("");
  const [transitioned, setTransitioned] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useUser();
  const [experts, setExperts] = useState<{ [key: string]: Expert }>({});
  const [expert, setExpert] = useState<Expert | undefined>(
    experts[params.expert || ""]
  );

  const nodeRef = useRef(null);
  const nodeRef2 = useRef(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);

  function onExpertChange(key: string) {
    const newExpert = experts[key];
    setExpert(newExpert);
    setMessageHistory([]);
    if (params.exerpt != key) {
      navigate("/chat/" + key);
    }
  }

  useEffect(() => {
    if (params.expert != expert?.value || "") {
      onExpertChange(params.expert || "");
    }
  }, [params]);

  useEffect(() => {
    async function fetchExperts() {
      const tmpExperts = await getExperts();
      const newExperts: { [key: string]: Expert } = {};
      tmpExperts.map(
        (e: APIExpert) => (newExperts[e.id.toString()] = apiExpertToExpert(e))
      );
      setExperts(newExperts);
    }

    fetchExperts();
  }, []);

  async function addMessage(message: ChatMessageProps, swap = false) {
    if (swap) {
      await setMessageHistory((prevState) => {
        return [...prevState.slice(0, -1), message];
      });
    } else {
      await setMessageHistory((prevState) => {
        return [...prevState, message];
      });
    }
  }

  async function addMessages(messages: Array<ChatMessageProps>) {
    await setMessageHistory((prevState) => {
      return [...prevState, ...messages];
    });
  }

  async function submitMessage(question: string, customPrompt: string = "") {
    // if(!user || !('id' in user)){
    //   // alert('Please log in...')
    //   setErrors(['Please log in...']);
    //   return;
    // }

    if (!expert) {
      setErrors(["Please pick an expert..."]);
      return;
    }

    setErrors([]);

    const msgHist = [...messageHistory.map((x) => x.message), question].slice(
      -5
    );
    console.log(msgHist);

    // const question = curText;
    const newMessage = {
      message: question,
      isexpert: false,
      containerRef: messageContainerRef,
    };

    await addMessages([
      newMessage,
      {
        message: "",
        isexpert: true,
        isPlaceholder: true,
        containerRef: messageContainerRef
      },
    ]);

    setCurText("");

    await new Promise((r) => setTimeout(r, 2000));

    const answer = await submitQuestionWithHist(
      expert.value,
      msgHist,
      "",
      (window as any).streamId,
      (window as any).sessionId // Pass the stream ID if available
    );
    // const answer = await submitQuestion(expert.value, question, customPrompt)
    // const answer = {answer: get_random_text(Math.round(Math.random() * 1000))}
    const respMessage = {
      message: answer.answer,
      isexpert: true,
      containerRef: messageContainerRef,
    };
    await addMessage(respMessage, true);
  }

  let messages = [];

  for (let i = 0; i < 100; i++) {
    let m = {
      message: get_random_text(Math.round(Math.random() * 1000)),
      isexpert: i % 2 == 1,
    };

    messages.push(m);
  }

  function getAutoSize() {
    return { maxRows: 7, minRows: 1 };
    return messageHistory.length
      ? { maxRows: 7, minRows: 1 }
      : { maxRows: 10, minRows: 4 };
  }

  return (
    <>
      {/* <Select
        onChange={onExpertChange}
        value={expert?.value}
        showSearch
        placeholder="Select an expert"
        size="large"
        options={Object.values(experts)}
        style={{ position: "fixed" }}
      /> */}

      <Row gutter={[15, 15]} style={{ height: "100%" }}>
        <Col span={12}>
          {expert && (
            <div
              style={{
                borderRadius: 12,
                overflow: "hidden",
                height:"calc(100% - 13px)",
                marginLeft: -6
              }}
            >
              <VideoStream
                key={expert.value}
                expertId={parseInt(expert.value)}
                idleVideoUrl={expert.idleVideoUrl}
                onStreamIdAvailable={(streamId, sessionId) => {
                  console.log("StreamId available", streamId);
                  (window as any).streamId = streamId;
                  (window as any).sessionId = sessionId;
                }}
              />
            </div>
          )}
        </Col>
        <Col span={12}>
          <Flex
            justify={"flex-end"}
            align="center"
            style={{ minHeight: "100%", maxHeight: "100vh", overflow: "auto" }}
            vertical
          >
            <Transition
              onEnter={() => {
                setTransitioned(false);
              }}
              onExiting={() => {
                setTransitioned(true);
              }}
              nodeRef={nodeRef}
              in={messageHistory.length == 0}
              timeout={duration}
            >
              {(state) => (
                <>
                  <div
                    ref={nodeRef}
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                  >
                    {messageHistory.length && state == "exited" ? null : (
                      <Title style={{ margin: 0 }} level={1}>
                        Ask {expert?.friendlyName}
                      </Title>
                    )}
                  </div>
                  <div ref={messageContainerRef} style={{paddingBottom:'25px', maxHeight:'75vh', overflow:'auto', width:'100%'}}>
                  {messageHistory.length && state == "exited"
                    ? messageHistory.map((x, i) => {
                        return (
                          <ChatMessage
                            containerRef={messageContainerRef}
                            key={
                              "message_" +
                              i.toString() +
                              (x.isPlaceholder ? "_t" : "_f")
                            }
                            message={x.message}
                            isPlaceholder={x.isPlaceholder}
                            isexpert={x.isexpert}
                            expertname={expert ? expert.friendlyName : ""}
                          />
                        );
                      })
                    : null}
                    </div>
                </>
              )}
            </Transition>
            {/* <ChatBox onMessage={submitMessage} conversationStarted={messageHistory.length>0 && transitioned} autoSize={getAutoSize()} experts={experts} expert={expert} onExpertChange={onExpertChange}/> */}
            <Transition
              nodeRef={nodeRef2}
              in={!transitioned}
              timeout={duration}
            >
              {(state) => (
                <div
                  ref={nodeRef2}
                  style={{
                    ...{
                      position: "sticky",
                      bottom: 25,
                      marginTop: 50,
                      width: "100%",
                    },
                    ...defaultStyleChat,
                    ...transitionStylesChat[state],
                  }}
                >
                  <ChatBox
                    errors={errors}
                    onMessage={submitMessage}
                    conversationStarted={
                      messageHistory.length > 0 &&
                      (state == "exiting" || state == "exited")
                    }
                    autoSize={getAutoSize()}
                    expert={expert}
                  />
                </div>
              )}
            </Transition>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default Chat;
