import React from "react";
import { Card, Col, Flex, Grid, List, Row, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { experts } from "../code/utils";
import { Navigate, useNavigate } from "react-router-dom";

const Discover: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Flex style={{ width: "100%", height: "100%" }} vertical>
      <Title
        level={4}
        style={{ fontWeight: "bold", margin: 0, marginBottom: 13 }}
      >
        Featured AI Twins
      </Title>
      <Row gutter={[13, 13]}>
        {Array.from(experts.values()).map((x) => {
          return (
            <Col span={8}>
              <div
                style={{
                  backgroundImage: `url(${x.image})`,
                  backgroundSize: "cover",
                  cursor: "pointer",
                  paddingBottom: "80%",
                  borderRadius: 20,
                  position: "relative",
                  backgroundPosition: "center center",
                }}
                onClick={() => {
                  navigate("/chat/" + x.value);
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background:
                      "linear-gradient(transparent 50%, rgba(0,0,0,0.5))",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 20,
                    right: 13,
                    left: 13,
                  }}
                >
                  <Typography.Title
                    level={3}
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: 0,
                      color: "#FFF",
                    }}
                  >
                    {x.label}
                  </Typography.Title>
                  <Typography.Paragraph
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {x.subtitle}
                  </Typography.Paragraph>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      {/* // <List
        //     style={{width: '60%'}}
        //     grid={{
        //     gutter: 16,
        //     xs: 1,
        //     sm: 2,
        //     md: 4,
        //     lg: 4,
        //     xl: 6,
        //     xxl: 3,
        //     }}
        //     dataSource={Array.from(experts.values())}
        //     renderItem={(item) => (
        //     <List.Item style={{height:'100%'}}>
                
        //     </List.Item>
        //     )}
        // /> */}
    </Flex>
  );
};

export default Discover;
