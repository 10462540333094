import { Button, Form, Input } from "antd";

export default function SignIn(){

    return(
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h1>Sign In</h1>
            <Form style={{ width: "300px" }}>
            <Form.Item label="Email" name="email">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Password" name="password">
                <Input type="password" />
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit">
                Sign In
                </Button>
            </Form.Item>
            </Form>
        </div>
    )
}