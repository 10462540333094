const config_local = {
    company_name: 'Twinfinity Inc',
    api_base: 'http://localhost:8000/api'
};

const config_remote = {
    company_name: 'Twinfinity, Inc',
    api_base: '/api'
};

const config = config_remote;

export { config };