import React, { ReactElement, useState, useEffect } from 'react';
import { Flex, Spin, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { TypeAnimation } from 'react-type-animation';
import { ReactTyped } from 'react-typed';

const { Title } = Typography


interface ChatMessageProps {
    message: string,
    expertname?: string,
    isexpert: boolean,
    avatar?: ReactElement,
    isPlaceholder? : boolean,
    containerRef: React.RefObject<HTMLDivElement>
}

function onType(ref: React.RefObject<HTMLDivElement>){
    // const limit = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
        // document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
        if(ref.current){
            ref.current.scrollTo({
                top: ref.current.scrollHeight,
                left: 0,
                behavior: "smooth",
            });
        }
        
    //     window.scrollTo({
    //     top: limit,
    //     left: 0,
    //     behavior: "smooth",
    //   });
}

function buildSequence(s: string, containerRef: React.RefObject<HTMLDivElement>){
    // Convert the string to an array of characters
    if(s === undefined){
        return []
    }
    const characters = s.split('');
    let curString = ''

    // Initialize an array to store the result
    const result = new Array<string|(()=>void)>();

    // Loop through each character in the characters array
    characters.forEach((char, index) => {
        // Add the character to the result array
        curString = curString + char;
        if(index%50==0){
            result.push(curString);
            result.push(()=>onType(containerRef))
        }
        
    });

    result.push(s);
    result.push(()=>onType(containerRef));

    return result;
}


const ChatMessage: React.FC<ChatMessageProps> = (props) => {

    useEffect(()=>{
      onType(props.containerRef)
    },[])



  return (
    <Flex justify={props.isexpert ? 'flex-start' : 'flex-end'} style={{width:'75%'}}>
        <div style={{marginRight:'20px', color:'#1677FF', marginTop:'30px'}}>
            {props.isexpert ? props.avatar || <UserOutlined style={{fontSize: '1.5em'}}/> : null}
        </div>
        <Flex style={{maxWidth:'60%'}} vertical>
            <Flex style={{width:'100%'}} justify={props.isexpert ? 'flex-start' : 'flex-end'}>
                <Title  level={5}>
                    {props.isexpert? props.expertname : 'You'}
                </Title>
            </Flex>
            <Typography >
                {props.isPlaceholder ? <Spin/> : null}
                {props.isexpert?
                    <TypeAnimation 
                        sequence={buildSequence(props.message, props.containerRef)}
                        wrapper="span"
                        speed={85}
                        cursor={false}
                    />
                    :
                    props.message}
            </Typography>
        </Flex>
        <div style={{marginLeft:'20px', marginTop:'30px'}}>
            {props.isexpert ? null : props.avatar || <UserOutlined style={{fontSize: '1.5em'}}/>}
        </div>
    </Flex>
  );
};

// export default ChatMessage;
export { type ChatMessageProps, ChatMessage };