import { DribbbleOutlined, ExperimentOutlined, KeyOutlined, PoundOutlined, ReadOutlined, RocketOutlined, RubyOutlined, UnlockOutlined } from "@ant-design/icons";
import { config } from "./vars";
import { ReactElement } from "react";

function get_random_text(len: number){
    const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';
    const lorem_len = lorem.length;

    const iterations = Math.floor(len / lorem_len);
    const additional_chars = len - (lorem_len * iterations);

    return lorem.repeat(iterations) + lorem.slice(0,additional_chars);
}


  interface Starter{
    icon: ReactElement,
    label: string
  }
  
  interface Expert{
    value:string,
    label:string,
    summary: string,
    friendlyName: string,
    starters: Array<Starter>,
    prompt: string,
    path: string,
    idleVideoUrl: string,
    image?: string,
    subtitle?: string
  }

  type APIExpert = {
    id: number;
    base_prompt?: string;
    speaking_style?: string;
    background?: string;
    avoid?: string;
    rules?: string;
    only_discuss?: string;
    chat_name: string;
    friendly_name: string;
    idle_video_url: string;
  };
  
  type APIExpertInsert = {
    base_prompt?: string;
    speaking_style?: string;
    background?: string;
    avoid?: string;
    rules?: string;
    only_discuss?: string;
    friendly_name: string;
  };

    
  const experts = new Map<string, Expert>([
    ['1', {
      value:'1',
      label: 'Mark Cuban',
      friendlyName: 'Mark',
      image: 'https://media-cldnry.s-nbcnews.com/image/upload/t_fit-560w,f_auto,q_auto:best/rockcms/2023-11/231128-mark-cuban-mb-1351-f8de9a.jpg',
      path: '/qa/1',
      summary: 'Mark Cuban is an American businessman and television personality. He is the former principal owner and current minority owner of the Dallas Mavericks of the National Basketball Association, co-owner of 2929 Entertainment, and one of the main "sharks" on the ABC reality television series Shark Tank.',
      prompt: '',
      subtitle: 'Businessman, Investor, Tech',
      starters: [
        {icon: <RubyOutlined/>, label:'Investment ideas'},
        {icon: <DribbbleOutlined/>,label:'Thoughts on the NBA draft'},
        {icon: <PoundOutlined/>,label:'Is crypto a good investment?'},
        {icon: <RocketOutlined/>,label:'Advice for entrepreneurs'}
      ],
      idleVideoUrl: ""
    }],
    ['2', {
      value:'2',
      label: 'Robert Azelby',
      friendlyName: 'Bob',
      path: '/qa/2',
      summary: `Mr. Azelby is an experienced Chief Executive Officer and veteran board member who has spent over 30 years in the biopharmaceutical industry.
      
      He served as President and Chief Executive Officer of Eliem Therapeutics, Inc., from October 2020 to February 2023. Prior to Eliem, he was the Chief Executive Officer of Alder Biopharmaceuticals, Inc., from June 2018 until it was acquired by H. Lundbeck in 2019. Before joining Alder BioPharmaceuticals, Mr. Azelby was the Chief Commercial Officer of Juno Therapeutics for three years until it was acquired by Celgene Corporation. Prior to Juno, he spent 15 years in commercial roles at Amgen, Inc.
      
      Mr. Azelby serves on the boards of directors of Clovis Oncology, Inc., and Chinook Therapeutics, Inc. Previously, he served on the boards of directors for Eliem Therapeutics, Alder BioPharmaceuticals, Cascadian Therapeutics, Inc., and Immunomedics, Inc. He holds a BA in Economics and Religious Studies from the University of Virginia and an MBA from Harvard Business School.`,
      prompt: '',
      subtitle: 'Biopharmaceuticals',
      image: 'https://agidlemjkrcszwtpxdfk.supabase.co/storage/v1/object/public/uploads/bob.png?t=2024-09-30T04%3A23%3A07.135Z',
      starters: [
        {icon: <KeyOutlined/>, label:'How to get your foot in the door'},
        {icon: <ReadOutlined/>, label:'What is your book about?'},
        {icon: <UnlockOutlined/>, label:'How to overcome fears'},
        {icon: <ExperimentOutlined/>, label:'The ethics of pharma companies'}
      ],
      idleVideoUrl: ""
    }]
  ])


  export function apiExpertToExpert(e:APIExpert):Expert{
    return {
      value: e.id.toString(),
      label: e.friendly_name,
      friendlyName: e.friendly_name,
      path: '/qa/' + e.id.toString(),
      summary: experts.get(e.id.toString())?.summary || '',
      prompt: e.base_prompt || '',
      starters: experts.get(e.id.toString())?.starters || [],
      idleVideoUrl: e.idle_video_url || ''
    }
  }


interface User{
    id: number,
    username: string,
    name: string,
    email: string,
    sub: string
}

const expertOpts = Array.from(experts.values());

interface Answer{
  answer: string
}



async function submitQuestionWithHist(expert_id:string, chatHistory:Array<string>, customPrompt='', stream_id?: string, session_id?: string):Promise<Answer>{

  // let expert = experts.get(expert_id);

  // if(!expert){
  //   return {answer:''};
  // }

  const data = {
    prompt: '',
    chat_history: chatHistory,
    stream_id,
    session_id
  }

  console.log(data)

  const response = await fetch(
    config.api_base + '/video/' + expert_id + "/stream/qa", 
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  
  const resp = await response.json();
  console.log(resp)
  return resp;
}


async function getExperts(): Promise<Array<APIExpert>>{
  const response = await fetch(
    config.api_base + '/qa/experts', 
    {
      method: "GET",
    }
  );

  const resp = await response.json();
  console.log(resp)
  return resp;
  
}

export { type Expert, type User, type APIExpert, type APIExpertInsert, get_random_text, getExperts, submitQuestionWithHist, experts, expertOpts }