

import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppLayout from './components/AppLayout'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider, theme } from 'antd';

import Home from './pages/Home';
import Chat from './pages/Chat';
import Discover from './pages/Discover';
import Survey from './pages/Survey';
import ExpertSettings from './pages/ExpertSettings';
import CreateExpert from './pages/CreateExpert';
import SignIn from './pages/SignIn';
import NotAuthorized from './pages/NotAuthorized';



function App() {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#01ADC5"
        }

      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout/>}>
            <Route index element={<Discover/>} />
            <Route path="chat/" element={<Chat/>} />
            <Route path="create/" element={<CreateExpert/>} />
            <Route path="signin/" element={<SignIn/>} />
            <Route path="unauthorized/" element={<NotAuthorized/>} />
            <Route path="chat/:expert" element={<Chat/>} />
            <Route path="settings/" element={<ExpertSettings/>} />
            <Route path="survey" element={<Survey/>} />
            <Route path="*" element={<div>404 Not Found</div>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
