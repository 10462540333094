import React, { useState, useEffect, useRef } from "react";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  HomeOutlined,
  ReadOutlined,
  FileAddOutlined,
  HistoryOutlined,
  LineChartOutlined,
  GlobalOutlined,
  CommentOutlined,
  CompassOutlined,
  BookOutlined,
  LoginOutlined,
  LogoutOutlined,
  MessageOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Breadcrumb,
  Flex,
  Layout,
  Menu,
  theme,
  Button,
  Avatar,
  Typography,
} from "antd";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import type { User } from "../code/utils";
import { config } from "../code/vars";

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

type ContextType = { user: User | null };

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <Breadcrumb style={{ margin: "16px 0" }}>
      {breadcrumbs.map(({ breadcrumb }) => (
        <Breadcrumb.Item key={breadcrumb?.toString()}>
          {breadcrumb}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState<User>();
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const signIn = () => {
    window.location.href =
      "https://tsonguyen.pythonanywhere.com/authorize/linkedin?redirect_uri=https://tsonguyen.pythonanywhere.com/callback/linkedin?next=" +
      window.location.href;
  };

  async function getProfile() {
    const response = await fetch(config.api_base + "/profile", {
      method: "GET",
      credentials: "include",
    });

    const curUser = await response.json();
    console.log(curUser);
    setUser(curUser);
  }

  function getLoginText() {
    return user && "id" in user ? (
      <p style={{ fontSize: ".9em", color: "#FFFFFFA6" }}>
        {/* <Avatar style={{backgroundColor: '#FFFFFFA6'}} shape="square" size="small" icon={<UserOutlined />} /> */}
        <UserOutlined style={{ fontSize: "1.3em" }} />
        {collapsed ? null : (
          <span style={{ marginLeft: "5px" }}>{user.email}</span>
        )}
      </p>
    ) : (
      <Button
        onClick={() =>
          navigate(
            (window.location.href =
              "https://tsonguyen.pythonanywhere.com/authorize/linkedin?redirect_uri=https://tsonguyen.pythonanywhere.com/callback/linkedin?next=" +
              window.location.href)
          )
        }
      >
        login
      </Button>
    );
  }

  const menuItems = [
    {
      key: "home",
      label: "Home",
      onClick: () => {
        navigate("/");
      },
      icon: <HomeOutlined />,
    },
    {
      key: "settings",
      label: "Settings",
      onClick: () => {
        navigate("/unauthorized");
      },
      icon: <SettingOutlined />,
    },
    {
      key: "signin",
      label: user ? "Sign out" : "Sign in",
      onClick: () => {
        navigate("/signin");
      },
      icon: user ? <LogoutOutlined /> : <LoginOutlined />,
    },
  ];

  // useEffect(()=>{
  //   getProfile();
  // }, [])

  return (
    <Layout style={{ minHeight: "100vh"}}>
      <Sider
        style={{
          overflow: "auto",
          height: "calc(100vh - 26px)",
          position: "sticky",
          marginTop: 13,
          marginLeft: 13,
          background: "#121212",
          borderRadius: borderRadiusLG,
        }}
        width={250}
        collapsible={false}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Flex vertical style={{ padding: 15, height:"100%" }}>
          <Title
            type="secondary"
            level={4}
            style={{ color: "white", marginTop: 0, fontWeight: "bold" }}
          >
            {collapsed ? null : "Twinfinity"}
          </Title>

          <Menu
            theme="dark"
            defaultSelectedKeys={["home"]}
            mode="inline"
            items={menuItems}
            style={{ background: "transparent" }}
          />

          <div
            style={{
              marginTop: "auto",
              color:"rgba(255,255,255,0.5)"
            }}
          >
            {config.company_name} ©{new Date().getFullYear()}
          </div>
        </Flex>
        {/* <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} /> */}
      </Sider>
      <Layout>
        <Content
          style={{ margin: "0 13px", display: "flex", flexDirection: "column" }}
        >
          <Flex
            vertical
            style={{
              padding: 24,
              paddingTop: 18,
              paddingBottom: 1,
              marginTop: 13,
              marginBottom: 0,
              flexGrow: 1,
              background: "linear-gradient(to bottom right, #121212, #262626)",
              borderRadius: borderRadiusLG,
              overflow: "auto",
              maxHeight: "calc(100vh - 26px)",
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
            }}
            ref={containerRef}
          >
            <div style={{ flexGrow: 199, width: "100%" }}>
              <Outlet context={{ user }} />
            </div>
          </Flex>
        </Content>
      </Layout>
    </Layout>
  );
};

export function useUser() {
  return useOutletContext<ContextType>();
}

export default App;
